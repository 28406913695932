<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Upload from "@/components/widgets/file-documents";

export default {
  locales: {
    pt: {
      "My Documents": "Meus Documentos",
      "The document is being evaluated": "O documento esta sendo aváliado.",
      "The document has been send": "Documento enviado com sucesso!",
      "The document is being evaluated.": "O documento está sendo validado.",
      "Choose the file": "Escolha o arquivo",
      Send: "Enviar",
      Disapproved: "Reprovado",
      "On approval": "Em análise",
      Okay: "Aprovado",
      Resend: "Reenviar",
    },
    es: {
      "My Documents": "Mis documentos",
      "The document is being evaluated": "El documento esta siendo evaluado.",
      "The document has been send": "El documento ha sido enviado.",
      "The document is being evaluated.": "El documento está siendo evaluado.",
      "Choose the file": "Elija el archivo",
      Send: "Enviar",
      Disapproved: "Desaprobado",
      "On approval": "En aprovacion",
      Okay: "Okey",
      Resend: "Reenviar",
    },
  },
  components: {
    Layout,
    Upload
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      modal: {
        status: false,
        id: null,
        title: null,
      },
    };
  },
  methods: {
    getDocuments() {
      this.modal.status = false;
      this.modal.id = null;
      this.modal.title = null;

      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("documents")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
    addDocument(document) {
      if (
        document.file.status == "pending" ||
        document.file.status == "repproved"
      ) {
        this.modal.title = document.type.title;
        this.modal.id = document.type.id;
        this.choosed = null;
        this.modal.status = true;
      }
    },
  },
  mounted() {
    this.getDocuments();
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('My Documents') }}</h3>
        <p></p>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="table.errored">
          {{
          t(
          "We apologize, we are unable to retrieve information at this time. Please try again later."
          )
          }}
        </div>
        <div v-else-if="table.empty">{{ t("No records found.") }}</div>
        <div v-else class="table-responsive">
          <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
          <table v-else class="table table-nowrap">
            <thead class="thead-light">
              <tr>
                <th>Documento</th>
                <th>Status</th>
                <th></th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td class="align-middle">{{ td.type.title }}</td>
                <td class="align-middle">
                  <span v-if="td.file.status == 'pending'" class="p-3 px-4 badge badge-soft-info font-size-14">{{ t("Pendente") }}</span>
                  <span v-else-if="td.file.status == 'waiting'" class="p-3 px-4 badge badge-soft-warning font-size-14">{{ t("Aguardando validação") }}</span>
                  <span v-else-if="td.file.status == 'repproved'" class="p-3 px-4 badge badge-soft-danger font-size-14">{{ t("Reprovado") }}</span>
                  <span v-else-if="td.file.status == 'approved'" class="p-3 px-4 badge badge-soft-success font-size-14">{{ t("Aprovado") }}</span>
                </td>
                <td>
                  <a v-if="td.file.url" class="btn btn-outline-default" target="_blank" :href="td.file.url">Abrir arquivo</a>
                </td>
                <td class="align-middle">
                  <button class="btn btn-default" @click="addDocument(td)" :disabled="td.file.status == 'waiting' || td.file.status == 'approved'">{{ t("Send") }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal v-model="modal.status" :title="t('Send') + ' ' + modal.title" modal-class="modal-right" hide-footer>
      <div class="col-12">
        <Upload :id="modal.id" />
      </div>
    </b-modal>
  </Layout>
</template>
